import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const WhyWordpress = () => (
  <Layout>
    <SEO
      title="Why You Should Use WordPress as a Web Developer"
      description="Here's my top 7 reasons to use wordpress as a web developer. The tool that makes starting a blog and building a fan base easy. Flexible, secure and powerful"
    />
    <h1>Why You Should Use WordPress as a Web Developer</h1>
    <p>There are lots of arguments for and against the use of WordPress in the world of web development. There are many reasons for not using it such as the amount of bloat in the background and the lack of control. I could go on about the negatives but that is a subject for another article.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/iitjvBr.jpg"
        alt="Campbell data science why wordpress"
        description="Campbell data science why wordpress"
      />
    </div>
    <p>I want to go over the benefits of WordPress and why I use it as a web developer and data scientist. If you're interested in the top reasons <Link to="/data-scientist-learn-web-development/" style={{ textDecoration: "none" }}>
    why data scientist should learn web development
    </Link>, you're in luck because I wrote an article on it. Let me know if you agree or disagree. Or if you just want some inspiration.</p>

    <h2>Blogging</h2>
    <p>
    The number one reason for me is blogging. CMS (<a
      href="https://searchcontentmanagement.techtarget.com/definition/content-management-system-CMS"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    content management system
    </a>) like WordPress are very good at this. They handle the whole back end for you. If you find a good template and you know what you're doing, you can get a pretty nice front end too. 
    </p>
    <p>Blogging is essential if you want to get your ideas seen and build an audience. When a lot of us start in web development or any other creative role, there is this idea of 'build it and they will come'. This belief couldn't be more wrong and most people learn eventually.</p>
    <p>This brings me to my next point...</p>
    <h2>SEO</h2>
    <p>Blogging helps search engines recognise what your site is about and helps drive users that would be interested in your content. When they get to your site, it's up to you where you direct them to next. Essentially, blog posts can often be the first entry point to your site.</p>
    <p>You only have to do a few minutes of browsing for one of your favourite subject to realise a large percentage of the web is built on WordPress. WordPress makes many parts of the SEO process very easy. We will get into the plugins later but there are some popular ones like Yoast. They prompt you to do things that you should be doing like structuring your text to make it easy to read. It also gives you a visual representation of how your page will display in their SERP (<a
      href="https://moz.com/learn/seo/serp-features"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    search engine results page
    </a>).</p>
    <p>If you are serious about driving traffic and want to learn more about SEO, there is lots of information all over the web. Feel free to get in touch with me if you like and stay tuned because I will have a post coming on it soon.</p>
    <h2>Avoid Procrastination</h2>
    <p>Some of us like to plan a lot and get stuck in the analysis phase of a project often. I have been there myself. WordPress takes one big job out of the process. It allows you to get things done sooner.</p>
    <p>I could build a blog myself, as can many of you. The question is why would I when there is already a tried and tested tool that can do it for me? I read about the idea of the <a
      href="https://en.wikipedia.org/wiki/Minimum_viable_product"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    minimum viable product
    </a>.</p>
    <p>It's really interesting if you haven't heard about it. It is all about putting together a product that contains enough function and design that a potential customer can use it. Or so you can pitch the idea to an investor or business partners.</p>
    <p>I apply this idea to a lot of my work now and it helps me get ideas off the ground much sooner than I would have done previously. Most things in development can be changed later. If I wanted to build a blog myself to replace the WordPress one in the future, I can. If I wanted to change my domain name, I can (although I would need a very good reason). The point is to get things done.</p>
    <h2>Time Saving</h2>
    <p>I mentioned procrastination, sometimes that isn't the problem. At times it's just the amount of work we have to do. Well, WordPress can cut back on development time a lot.</p>
    <p>It's easy to think about time saved at the start of a project only but think about time during the project too. If you build your own blog, what happens if something breaks? What happens if some of your <a
      href="https://stackoverflow.com/questions/35236735/npm-warn-message-about-deprecated-package/36341065"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    dependencies become deprecated
    </a>? There are other things that can go wrong. Consider you had a day of writing content or marketing planned and find something major is broken.</p>
    <p>All of this is part of the life of a developer but I have saved plenty of time using WordPress.</p>
    <h2>Support</h2>
    <p>There is lots of support for WordPress coming from all angles. Developers create some amazing templates, although the best ones are paid. There are some great ones for free and they do the job but if you want to stand out a bit, go paid.</p>
    <p>WordPress is a great free service but let’s say you wanted to be a bit more professional and use your own domain name. Not just a sub domain of WordPress. You can go ahead and secure that domain name if you haven't already, then get some hosting.</p>
    <p>There are a few hosting providers that support WordPress, two that come to mind are Bluehost and SiteGround. I personally use SiteGround (it's where this site is hosted).</p>
    <p>If you want to learn more about getting a domain name and setting up hosting get in touch. Or you can come save my site and come back because I have a post planned to help with this.</p>
    <p>There is also support from developers who create some great add-ons which brings me to my next point.....</p>
    <h2>Plugins</h2>
    <p>Plugins are very high up on the list of why I use WordPress. I mentioned saving time and plugins really do help with this. There is a plugin for pretty much anything you can think of. In Google, just type 'WordPress' followed by the thing you are after and read some reviews. I would recommend <a
      href="https://yoast.com/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    Yoast SEO
    </a> if you are starting out. You don't have to follow everything it says but as I mentioned before it gives you a good idea of what to look for. It's free too.</p>
    <p>There are plugins for social media buttons and saving emails to a mailing list. Amazon even has a tool for generating links for products you recommend.</p>
    <p>I will make list of my favourite if you're interested, just get in touch and let me know.</p>
    <h2>The 80/20 rule</h2>
    <p>My final point and one that is related to time saving is the 80 20 rule, aka the <a
      href="https://en.wikipedia.org/wiki/Pareto_principle"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    Pareto principle
    </a>. It is all about figuring out which parts of your work bring the best results. Then focus your effort on those things. Get the blog out of the way and focus on amazing content and a great user experience.</p>
    <h2>Conclusion</h2>
    <p>For all the reasons listed above, I use WordPress as part of my web development tool kit. It sometimes gets a bad reputation with web developers but if you haven't used it before, it's worth a try.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default WhyWordpress
